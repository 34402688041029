import React from 'react';
import { graphql, Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const query = graphql`
  query($slug: String!) {
    contentfulServiceCard(slug: { eq: $slug }) {
      title
      slug
      icon {
        file {
          url
        }
      }
      body {
        json
      }
    }
  }
`;

const ServicePage = ({ data }) => {
  const { title, body } = data.contentfulServiceCard;
  return (
    <Layout className='relative'>
      <SEO
        keywords={[
          `1С`,
          `1С ИТС`,
          `Сопровождение 1С`,
          `Купить 1С Сургут`,
          `Сопровождение 1С Пыть-Ях`,
          `софтмакс`,
          `${title}`
        ]}
        title={title}
      />
      <section className='p-6 max-w-4xl mx-auto '>
        <Link
          to='/services'
          className='mr-4 -mt-5 text-red-700 absolute  italic right-0'
        >
          назад в Сервисы
        </Link>
        <div className='flex flex-wrap'>
          <img
            className=' tl-5 bg-blue-500 rounded w-12 h-12 mr-4 '
            src={data.contentfulServiceCard.icon.file.url}
            alt='image'
          />

          <h2 className=' self-center text-2xl uppercase font-bold text-red-600'>
            {title}
          </h2>
          <Link
            to='/contact'
            className='px-4 py-1 self-center h-8 text-white ml-6 rounded-full uppercase bg-red-400 hover:bg-red-800 tracking-wider'
          >
            Заказать
          </Link>
        </div>
        <div>{documentToReactComponents(body.json)}</div>
      </section>
    </Layout>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ServicePage;
